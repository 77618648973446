import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

import parseError from "app/lib/parseError";
import { getProfiles } from "app/api/profile";
import {
  useActiveProfile,
  useSetActiveProfile,
} from "app/providers/ActiveProfileProvider";
import { PROFILES_QUERY } from "app/constants/commonQueries";
import { parseToken } from "app/lib/encryption";
import { useProfile } from "app/providers/ProfileProvider";
import { ActiveProfile } from "app/models/profile";

/**
 * Custom hook to get user profiles.
 *
 * @param enabled - A boolean to enable or disable the fetching of profiles.
 */

const useGetProfiles = (enabled = true) => {
  const activeProfile = useActiveProfile();
  const setActiveProfile = useSetActiveProfile();

  const prevProfileRef = useRef<ActiveProfile | null>(null);

  const profile = useProfile();

  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    [PROFILES_QUERY],
    getProfiles,
    {
      enabled,
      // 5 minutes
      staleTime: 1000 * 60 * 5,
    }
  );

  useEffect(() => {
    if (data && activeProfile) {
      const prevProfile = prevProfileRef.current;

      const profiles = data.data.data as ActiveProfile[];

      const defaultProfile = profiles?.find(
        (p) => p.id === activeProfile.id
      ) as ActiveProfile;

      if (JSON.stringify(prevProfile) !== JSON.stringify(defaultProfile)) {
        setActiveProfile({ ...defaultProfile });

        prevProfileRef.current = activeProfile;
      }
    }
  }, [activeProfile, setActiveProfile, data, prevProfileRef]);

  useEffect(() => {
    if (activeProfile) {
      const token = parseToken();

      if (data) {
        const profiles = data.data.data as ActiveProfile[];

        let defaultProfile = profiles.find((p) => p.id === activeProfile?.id);

        if (!defaultProfile) {
          defaultProfile =
            profiles.find((p) => p.profile_type === "personal") || profiles[0];
          setActiveProfile(defaultProfile);
        }

        if ((token?.uid as string) === activeProfile.user_id) return;
      }
    }

    if (!data) return;

    const profiles = data.data.data;

    const defaultSelectedProfile = profile?.business_default
      ? "business"
      : activeProfile?.profile_type || "personal";

    // Profile selected by default
    const defaultProfile = profiles.find(
      (p) => p.profile_type === defaultSelectedProfile
    );

    setActiveProfile(defaultProfile ?? profiles[0]);
  }, [data, setActiveProfile, profile, activeProfile]);

  return {
    profiles: data?.data.data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getProfiles: refetch,
    isFetching,
    isLoading,
  };
};

export default useGetProfiles;
