import { AUTH_URL } from "app/constants/variables";

import {
  CreateApiKeyPayload,
  CreateNextOfKinPayload,
  CreateWebhookPayload,
  CreateProfilePayload,
  FinalizeBvnChangePayload,
  FinalizePhoneChangePayload,
  GetNextOfKinResponse,
  GetProfileResponse,
  GetRequiredDocumentsResponse,
  GetSecurityResponse,
  GetUpgradeMethodResponse,
  GetUserLimitsResponse,
  InitializeBvnChangePayload,
  InitializeBvnChangeResponse,
  InitializePhoneChangePayload,
  UpdateAddressPayload,
  UpdateApiKeyPayload,
  UpdateDisplayCurrencyPayload,
  UpdateDOBPayload,
  UpdateNextOfKinPayload,
  UpdateProfilePayload,
  UpdateUsernamePayload,
  CreateApiKeyResponse,
} from "./types";

import request from "../request";
import { BaseResponse, GenericResponse } from "../types";
import { DisplayCurrency } from "app/models/displayCurrencies";
import { NameChangeRequirement } from "app/models/nameChangeRequirement";
import { PublicKey, ApiKey, ApiKeyPermission } from "app/models/api-keys";
import { EventOption, Webhook } from "app/models/webhook";
import { ActiveProfile } from "app/models/profile";

const defaultOption = { baseURL: AUTH_URL };

export const getSecurity = () => {
  return request.get<GetSecurityResponse>("/me/security", defaultOption);
};

export const getProfile = () => {
  return request.get<GetProfileResponse>("/me", defaultOption);
};

export const getProfiles = () => {
  return request.get<GenericResponse<ActiveProfile[]>>(
    "/profiles",
    defaultOption
  );
};

export const createProfile = (data: CreateProfilePayload) => {
  return request.post<GenericResponse<ActiveProfile>>(
    "/profiles",
    data,
    defaultOption
  );
};

export const getNextOfKin = () => {
  return request.get<GetNextOfKinResponse>("/me/next_of_kin", defaultOption);
};

export const updateUsername = (payload: UpdateUsernamePayload) => {
  return request.put<BaseResponse>(`/me/username`, payload, defaultOption);
};

export const updateDOB = (payload: UpdateDOBPayload) => {
  return request.put<BaseResponse>(`/me/dob`, payload, defaultOption);
};

export const initializePhoneChange = (
  payload: InitializePhoneChangePayload
) => {
  return request.post<BaseResponse>(
    `/me/security/phone/initialize`,
    payload,
    defaultOption
  );
};

export const finalizePhoneChange = (payload: FinalizePhoneChangePayload) => {
  return request.post<BaseResponse>(
    `/me/security/phone/finalize`,
    payload,
    defaultOption
  );
};

export const getUserLimits = () => {
  return request.get<GetUserLimitsResponse>("/me/limits", defaultOption);
};

export const getVerificationUpgradeMethod = () => {
  return request.get<GenericResponse<GetUpgradeMethodResponse>>(
    "/me/upgrade_methods",
    defaultOption
  );
};

export const initializeBvnChange = (payload: InitializeBvnChangePayload) => {
  return request.post<InitializeBvnChangeResponse>(
    `/me/security/bvn/initialize`,
    payload,
    defaultOption
  );
};

export const finalizeBvnChange = (payload: FinalizeBvnChangePayload) => {
  return request.post<BaseResponse>(
    `/me/security/bvn/finalize`,
    payload,
    defaultOption
  );
};

export const getRequiredDocuments = () => {
  return request.get<GetRequiredDocumentsResponse>(
    "/me/documents/types",
    defaultOption
  );
};

export const createNextofKin = (payload: CreateNextOfKinPayload) => {
  return request.post<BaseResponse>(`/me/next_of_kin`, payload, defaultOption);
};

export const updateNextofKin = (payload: UpdateNextOfKinPayload) => {
  return request.put<BaseResponse>(`/me/next_of_kin`, payload, defaultOption);
};

export const updateAddress = (payload: UpdateAddressPayload) => {
  return request.put<BaseResponse>(`/me/address`, payload, defaultOption);
};

export const getDisplayCurrency = () => {
  return request.get<GenericResponse<DisplayCurrency[]>>(
    "/me/display_currency",
    defaultOption
  );
};

export const getNameChangeRequirements = () => {
  return request.get<GenericResponse<NameChangeRequirement>>(
    "/me/name-change",
    defaultOption
  );
};

export const updateDisplayCurrency = (
  payload: UpdateDisplayCurrencyPayload
) => {
  return request.put<BaseResponse>(
    `/me/display_currency`,
    payload,
    defaultOption
  );
};

export const updateProfile = (payload: UpdateProfilePayload) => {
  return request.put<BaseResponse>("/me", payload, defaultOption);
};

export const getPublicKey = () => {
  return request.get<GenericResponse<PublicKey>>(
    "/api-keys/public_key",
    defaultOption
  );
};

export const listApiKeys = () => {
  return request.get<GenericResponse<ApiKey[]>>("/api-keys", defaultOption);
};

export const getApiKey = (id: string) => {
  return request.get<GenericResponse<ApiKey>>(`/api-keys/${id}`, defaultOption);
};

export const getApiKeyPermissions = () => {
  return request.get<GenericResponse<ApiKeyPermission>>(
    `/api-keys/permissions`,
    defaultOption
  );
};

export const updateApiKey = ({ id, ...data }: UpdateApiKeyPayload) => {
  return request.put<BaseResponse>(`/api-keys/${id}`, data, defaultOption);
};

export const rotateApiKey = (id: string) => {
  return request.put<GenericResponse<{ encoded_key: string }>>(
    `/api-keys/${id}/rotate`,
    null,
    defaultOption
  );
};

export const createApiKey = (
  data: CreateApiKeyPayload & { auth_code: string }
) => {
  return request.post<GenericResponse<CreateApiKeyResponse>>(
    "/api-keys",
    data,
    defaultOption
  );
};

export const deleteApiKey = (id: string) => {
  return request.delete<BaseResponse>(`/api-keys/${id}`, defaultOption);
};

export const createWebhook = (data: CreateWebhookPayload) => {
  return request.post<BaseResponse>("/webhooks", data, defaultOption);
};

export const listWebhooks = () => {
  return request.get<GenericResponse<Webhook[]>>("/webhooks", defaultOption);
};

export const listEventOptions = () => {
  return request.get<GenericResponse<EventOption[]>>(
    "/webhooks/event-options",
    defaultOption
  );
};

export const rotateWebhook = (id: string) => {
  return request.put<BaseResponse>(`/webhooks/${id}/secret`, {}, defaultOption);
};

export const deleteWebhook = (id: string) => {
  return request.delete<BaseResponse>(`/webhooks/${id}`, defaultOption);
};
