import * as React from "react";
import media from "app/styles/media";
import tw, { styled } from "twin.macro";
import clsx from "clsx";

import { ReactComponent as CrossError } from "app/assets/icons/cross-error.svg";

import Loader from "app/components/ui/Loader";
import StatusView from "app/components/ui/StatusView";
import {
  DASHBOARD_BODY_PADDING_Y,
  DASHBOARD_HEADER_HEIGHT,
  DASHBOARD_MOBILE_NAV_HEIGHT,
} from "app/constants/variables";

interface Props {
  error?: boolean;
  errorMessage?: string;
  onRetry?: () => void;
  retryText?: string;
  className?: string;
  screen?: boolean;
  page?: boolean;
  loading?: boolean;
}
export default function LoaderContainer(props: React.PropsWithChildren<Props>) {
  const {
    children,
    error,
    loading,
    page,
    screen,
    errorMessage = "Sorry, something went wrong.",
    onRetry,
    retryText,
    className,
    ...rest
  } = props;

  if (!loading && !error) return <>{children}</>;

  return (
    <Container className={clsx({ page, screen, error }, className)} {...rest}>
      {loading && (
        <div tw="p-[30px]">
          <Loader />
        </div>
      )}

      {error && (
        <StatusView
          status="error"
          errorIcon={<CrossError />}
          title="Something went wrong."
          description={errorMessage}
          primaryAction={{ label: retryText ?? "Retry", onAction: onRetry }}
          tw="max-w-full"
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  ${tw`w-full flex items-center justify-center`};

  &.error {
    ${tw`items-start`};
    padding-top: 94px;

    ${media.mobile} {
      ${tw`items-center`};
      padding-top: unset;
    }
  }

  &.screen {
    ${tw`h-screen mobile:px-0`};
  }

  &.page {
    min-height: calc(
      100vh - ${DASHBOARD_HEADER_HEIGHT}px - ${DASHBOARD_BODY_PADDING_Y}px -
        ${DASHBOARD_BODY_PADDING_Y}px
    );
    ${tw`xl:h-full`};

    max-height: 100%;

    ${tw`mobile:px-0`};

    ${media.mobile} {
      min-height: calc(
        100vh - ${DASHBOARD_HEADER_HEIGHT}px - ${DASHBOARD_MOBILE_NAV_HEIGHT}px
      );
    }
  }
`;
