import * as React from "react";
import { Navigate, Outlet, RouteObject } from "react-router-dom";

import {
  DASHBOARD_ROUTES_ID,
  HOME_PATH,
  KYB_PATH,
  PAYMENT_LINKS_PATH,
} from "app/constants/variables";

import DashboardLayout from "app/components/DashboardLayout";
import DashboardHeader from "app/components/ui/DashboardHeader";
import DashboardSideNav from "app/components/ui/DashboardSideNav";
import Header from "app/styles/Header";
import DashboardMobileNav from "app/components/ui/DashboardMobileNav";
import IntercomAppProvider from "app/providers/IntercomAppProvider";
import BalanceVisibilityProvider from "app/providers/BalanceVisibilityProvider";
import Auth from "app/views/Auth";
import PaymentLinksLayout from "app/components/PaymentLinksLayout";
import PaymentLinksHeader from "app/components/ui/PaymentLinksHeader";
import BankAccounts from "app/views/BankAccounts";
import OtherPaymentAccounts from "app/views/OtherPaymentAccounts";
import OtherPaymentAccountsAdd from "app/views/OtherPaymentAccountsAdd";
import BankAccountsAdd from "app/views/BankAccountsAdd";
import BankAccountDetails from "app/views/BankAccountDetails";
import AuthGuard from "app/components/AuthGuard";
import FullPageLayout from "app/components/layout/FullPageLayout";
import ActiveProfileProvider from "app/providers/ActiveProfileProvider";
import VerificationStatusProvider from "app/providers/VerificationStatusProvider";
import SpendContainer from "app/views/SpendContainer";
import { KYBStepNavigationProvider } from "app/providers/BusinessVerificationProvider";
import WalletAddresses from "app/views/WalletAddresses";

// TODO: lazy load all routes, excluding auth and explore

// Home routes
const Explore = React.lazy(() => import("app/views/Explore"));
const Onboarding = React.lazy(() => import("app/views/Onboarding"));
const WatchList = React.lazy(() => import("app/views/WatchList"));
const TopMovers = React.lazy(() => import("app/views/TopMovers"));
const TrendingNews = React.lazy(() => import("app/views/TrendingNews"));
const Notifications = React.lazy(() => import("app/views/Notifications"));
const ReactivateAccount = React.lazy(
  () => import("app/views/ReactivateAccount")
);
const FplAnnouncement = React.lazy(() => import("app/views/FplAnnouncement"));
const BushaPlaybackAnnouncement = React.lazy(
  () => import("app/views/BushaPlaybackAnnouncement")
);
const BushaFestCampaign = React.lazy(
  () => import("app/views/BushaFestCampaign")
);

const ReferredEarnings = React.lazy(() => import("app/views/ReferredEarnings"));
const AutomatedRecurringBuys = React.lazy(
  () => import("app/views/AutomatedRecurringBuys")
);
const NotificationAnnouncement = React.lazy(
  () => import("app/views/NotificationAnnouncement")
);
const VerifyKyc = React.lazy(() => import("app/views/VerifyKyc"));
const CashBack = React.lazy(() => import("app/views/CashBack"));
const PaymentLinks = React.lazy(() => import("app/views/PaymentLinks"));
const PaymentLinksCrypto = React.lazy(
  () => import("app/views/PaymentLinksCrypto")
);
const PaymentLinksRecipientProvider = React.lazy(
  () => import("app/providers/PaymentLinksRecipientProvider")
);

// Busha Pay routes
const BushaPayConfig = React.lazy(() => import("app/views/BushaPayConfig"));
const BushaPayConfigCurrencyOrder = React.lazy(
  () => import("app/views/BushaPayConfigCurrencyOrder")
);
const BushaPaySetPin = React.lazy(() => import("app/views/BushaPaySetPin"));
const BushaPayChangePin = React.lazy(
  () => import("app/views/BushaPayChangePin")
);
const BushaPaySecurityAuthentication = React.lazy(
  () => import("app/views/BushaPaySecurityAuthentication")
);

// Yield routes
const Yield = React.lazy(() => import("app/views/Yield"));
const YieldRedeem = React.lazy(() => import("app/views/YieldRedeem"));
const YieldSubscribe = React.lazy(() => import("app/views/YieldSubscribe"));
const YieldDetails = React.lazy(() => import("app/views/YieldDetails"));

// Spot routes
const Spot = React.lazy(() => import("app/views/Spot"));
const Connect = React.lazy(() => import("app/views/Connect"));
// const ConnectExpress = React.lazy(() => import("app/views/ConnectExpress"));
const ConnectPayment = React.lazy(() => import("app/views/ConnectPayment"));
// const ConnectPrime = React.lazy(() => import("app/views/ConnectPrime"))
const ConnectWithdrawal = React.lazy(
  () => import("app/views/ConnectWithdrawal")
);
const FiatPaymentOptions = React.lazy(
  () => import("app/views/FiatPaymentOptions")
);
const FiatWithdrawals = React.lazy(() => import("app/views/FiatWithdrawals"));
const MobileMoneyDeposit = React.lazy(
  () => import("app/views/MobileMoneyDeposit")
);
const MobileMoneyPayout = React.lazy(
  () => import("app/views/MobileMoneyPayout")
);
const SpotBankTransfer = React.lazy(() => import("app/views/SpotBankTransfer"));
const SpotCreateRecurringBuys = React.lazy(
  () => import("app/views/SpotCreateRecurringBuys")
);
const SpotManageRecurringBuys = React.lazy(
  () => import("app/views/SpotManageRecurringBuys")
);
const SpotSelectBet = React.lazy(() => import("app/views/SpotSelectBet"));
// Is this route needed?
const SpotSelectMobileNetwork = React.lazy(
  () => import("app/views/SpotSelectMobileNetwork")
);
const SpotSelectVoucher = React.lazy(
  () => import("app/views/SpotSelectVoucher")
);
const SpotTransactions = React.lazy(() => import("app/views/SpotTransactions"));
const SpotWalletBuy = React.lazy(() => import("app/views/SpotWalletBuy"));
const SpotWalletDetails = React.lazy(
  () => import("app/views/SpotWalletDetails")
);
const SpotWalletSell = React.lazy(() => import("app/views/SpotWalletSell"));
const SpotWalletSwap = React.lazy(() => import("app/views/SpotWalletSwap"));
const SpotWalletSend = React.lazy(() => import("app/views/SpotWalletSend"));
const SpotTransactionDetails = React.lazy(
  () => import("app/views/TransactionDetails")
);
const SpotWalletDeposit = React.lazy(
  () => import("app/views/SpotWalletDeposit")
);
const SpotViewAddress = React.lazy(() => import("app/views/SpotViewAddress"));
const LimitOrderDetails = React.lazy(
  () => import("app/views/LimitOrderDetails")
);
const VerifyBvn = React.lazy(() => import("app/views/VerifyBvn"));
const VoucherDeposit = React.lazy(() => import("app/views/VoucherDeposit"));
const USDPaymentOptions = React.lazy(
  () => import("app/views/FiatPaymentOptions/component/USDPaymentOptions")
);

// Trade routes
const Trade = React.lazy(() => import("app/views/Trade"));
const TradeTransactions = React.lazy(
  () => import("app/views/TradeTransactions")
);
const TradeTransactionDetails = React.lazy(
  () => import("app/views/TransactionDetails")
);

// Profile routes
const Profile = React.lazy(() => import("app/views/Profile"));
const DeveloperTools = React.lazy(() => import("app/views/DeveloperTools"));
const Webhooks = React.lazy(() => import("app/views/Webhooks"));
const CreateWebhook = React.lazy(() => import("app/views/CreateWebhook"));
const ApiKeys = React.lazy(() => import("app/views/ApiKeys"));
const ApiKeysAction = React.lazy(() => import("app/views/ApiKeysAction"));
const AboutUs = React.lazy(() => import("app/views/AboutUs"));
const Accounts = React.lazy(() => import("app/views/Accounts"));
const Team = React.lazy(() => import("app/views/Team"));
const AddTeamMember = React.lazy(() => import("app/views/AddTeamMember"));
const Roles = React.lazy(() => import("app/views/Roles"));
const AccountStatements = React.lazy(
  () => import("app/views/AccountStatements")
);
const EditNextOfKin = React.lazy(
  () => import("app/views/PersonalDetailsNextOfKin")
);
const LevelsAndLimits = React.lazy(() => import("app/views/LevelsAndLimits"));
const AccountLimits = React.lazy(() => import("app/views/AccountLimits"));
const NotificationSettings = React.lazy(
  () => import("app/views/NotificationSettings")
);
const PaymentMethods = React.lazy(() => import("app/views/PaymentMethods"));
const PersonalDetails = React.lazy(() => import("app/views/PersonalDetails"));
const BusinessDetails = React.lazy(() => import("app/views/BusinessDetails"));
const ProfileTwoStepAuth = React.lazy(
  () => import("app/views/ProfileTwoStepAuth")
);
const ProfileChangeName = React.lazy(
  () => import("app/views/ProfileChangeName")
);
const Preferences = React.lazy(() => import("app/views/Preferences"));
const ReferAndEarn = React.lazy(() => import("app/views/ReferAndEarn"));
const ResetPassword = React.lazy(() => import("app/views/ResetPassword"));
const Security = React.lazy(() => import("app/views/Security"));
const OneStepWithdrawal = React.lazy(
  () => import("app/views/OneStepWithdrawal")
);
const ManageOneStepWithdrawal = React.lazy(
  () => import("app/views/ManageOnestepWithdrawal")
);
const VerificationDocuments = React.lazy(
  () => import("app/views/VerificationDocuments")
);

// Spend Routes
const SpendAirtime = React.lazy(() => import("app/views/SpendAirtime"));
const SpendData = React.lazy(() => import("app/views/SpendData"));
const SpendBeneficiaries = React.lazy(
  () => import("app/views/SpendBeneficiaries")
);
const SpendBet = React.lazy(() => import("app/views/SpendBet"));
const BuySpendAirtime = React.lazy(() => import("app/views/BuySpendAirtime"));
const BuySpendData = React.lazy(() => import("app/views/BuySpendData"));
const SpendVouchers = React.lazy(() => import("app/views/SpendVouchers"));
const SelectVoucherStores = React.lazy(
  () => import("app/views/SelectVoucherStores")
);
const SpendVouchersTransactions = React.lazy(
  () => import("app/views/SpendVouchersTransactions")
);
const SpendVoucherTransactionDetails = React.lazy(
  () => import("app/views/SpendVoucherTransactionDetails.tsx")
);
const SpendTransactionDetails = React.lazy(
  () => import("app/views/SpendTransactionDetails")
);
const PurchaseSpendVoucher = React.lazy(
  () => import("app/views/PurchaseSpendVoucher")
);
const SpendVoucherStore = React.lazy(
  () => import("app/views/SpendVoucherStore")
);
const CreditSpendBettingAccount = React.lazy(
  () => import("app/views/CreditSpendBettingAccount")
);
const SelectBetting = React.lazy(() => import("app/views/SelectBetting"));
const SpendTransactions = React.lazy(
  () => import("app/views/SpendTransactions")
);
const VoucherRedemptionHistory = React.lazy(
  () => import("app/views/VoucherRedemptionHistory")
);
const PersonalVerification = React.lazy(
  () => import("app/views/PersonalVerification")
);

const FiatDepositDetails = React.lazy(
  () => import("app/views/FiatDepositDetails")
);

const ConfirmAddress = React.lazy(() => import("app/views/ConfirmAddress"));

const CreateBusiness = React.lazy(() => import("app/views/CreateBusiness"));
const BusinessVerification = React.lazy(
  () => import("app/views/BusinessVerification")
);

const KYBProfile = React.lazy(
  () => import("app/views/BusinessVerification/steps/BusinessProfile")
);

const KYBAddress = React.lazy(
  () => import("app/views/BusinessVerification/steps/BusinessAddress")
);

const KYBRegistrationDetails = React.lazy(
  () =>
    import("app/views/BusinessVerification/steps/BusinessRegistrationDetails")
);

const KYBOwnershipStructure = React.lazy(
  () =>
    import("app/views/BusinessVerification/steps/BusinessOwnershipStructure")
);

const KYBTransactionInformation = React.lazy(
  () =>
    import(
      "app/views/BusinessVerification/steps/BusinessTransactionInformation"
    )
);

const KYBDocuments = React.lazy(
  () =>
    import("app/views/BusinessVerification/steps/BusinessComplianceDocuments")
);

const KYBReview = React.lazy(
  () => import("app/views/BusinessVerification/steps/Review")
);

const VerificationReview = React.lazy(
  () => import("app/views/VerificationReview")
);

const VerificationSubmission = React.lazy(
  () => import("app/views/VerificationSubmission")
);

const RampTransfer = React.lazy(() => import("app/views/RampTransfer"));
const RampTransferDetails = React.lazy(
  () => import("app/views/RampTransferDetails")
);

const routesObject: RouteObject[] = [
  { path: "/oauth/:token", element: <Auth /> },
  {
    path: `/${PAYMENT_LINKS_PATH}/:butag`,
    element: (
      <PaymentLinksRecipientProvider>
        <PaymentLinksLayout header={<PaymentLinksHeader />} />
      </PaymentLinksRecipientProvider>
    ),
    children: [
      {
        index: true,
        element: <PaymentLinks />,
      },
      {
        path: "crypto",
        element: <PaymentLinksCrypto />,
      },
      {
        path: "*",
        element: <Header> 404 👀</Header>,
      },
    ],
  },
  {
    path: "/create-business",
    element: (
      <ActiveProfileProvider>
        <AuthGuard>
          <FullPageLayout>
            <CreateBusiness />
          </FullPageLayout>
        </AuthGuard>
      </ActiveProfileProvider>
    ),
  },

  {
    path: KYB_PATH,
    element: (
      <ActiveProfileProvider>
        <AuthGuard>
          <KYBStepNavigationProvider>
            <FullPageLayout>
              <BusinessVerification />
            </FullPageLayout>
          </KYBStepNavigationProvider>
        </AuthGuard>
      </ActiveProfileProvider>
    ),
    children: [
      {
        path: "profile",
        element: <KYBProfile />,
      },
      {
        path: "address",
        element: <KYBAddress />,
      },
      {
        path: "registration",
        element: <KYBRegistrationDetails />,
      },
      {
        path: "ownership",
        element: <KYBOwnershipStructure />,
      },
      {
        path: "transaction",
        element: <KYBTransactionInformation />,
      },
      {
        path: "documents",
        element: <KYBDocuments />,
      },
      {
        path: "review",
        element: <KYBReview />,
      },
      {
        index: true,
        element: <Navigate to="profile" replace />,
      },
    ],
  },

  {
    element: (
      <BalanceVisibilityProvider>
        <IntercomAppProvider>
          <ActiveProfileProvider>
            <VerificationStatusProvider>
              <AuthGuard>
                <DashboardLayout
                  header={<DashboardHeader />}
                  sidenav={<DashboardSideNav />}
                  mobilenav={<DashboardMobileNav />}
                />
              </AuthGuard>
            </VerificationStatusProvider>
          </ActiveProfileProvider>
        </IntercomAppProvider>
      </BalanceVisibilityProvider>
    ),
    id: DASHBOARD_ROUTES_ID,
    children: [
      {
        path: HOME_PATH,
        element: <Explore />,
      },
      {
        path: "/onboarding",
        element: <Onboarding />,
      },
      { path: "/personal-verification", element: <PersonalVerification /> },
      { path: "/confirm-address", element: <ConfirmAddress /> },
      {
        path: "/watch-list",
        element: <WatchList />,
      },
      {
        path: "/top-movers",
        element: <TopMovers />,
      },
      {
        path: "/trending-news",
        element: <TrendingNews />,
      },
      {
        path: "/automated-recurring-buys",
        element: <AutomatedRecurringBuys />,
      },
      {
        path: "/verify-kyc",
        element: <VerifyKyc />,
      },
      {
        path: "/notifications",
        element: <Outlet />,
        children: [
          { index: true, element: <Notifications /> },
          { path: ":id", element: <NotificationAnnouncement /> },
        ],
      },
      {
        path: "promo",
        element: <Outlet />,
        children: [
          { path: "reactivate-account", element: <ReactivateAccount /> },
        ],
      },

      {
        path: "promo",
        element: <Outlet />,
        children: [{ path: "busha-fest", element: <BushaFestCampaign /> }],
      },

      {
        path: "announcements",
        element: <Outlet />,
        children: [
          { path: "fpl", element: <FplAnnouncement /> },
          {
            path: "busha-playback",
            element: <BushaPlaybackAnnouncement />,
          },
        ],
      },

      {
        path: "/yield",
        element: <Outlet />,
        children: [
          { index: true, element: <Yield /> },
          { path: "redeem", element: <YieldRedeem /> },
          { path: "subscribe", element: <YieldSubscribe /> },
          { path: ":currency", element: <YieldDetails /> },
        ],
      },
      {
        path: "/spot",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <Spot />,
          },
          {
            path: "connect/:id",
            element: <ConnectPayment />,
          },
          { path: "verify-bvn", element: <VerifyBvn /> },
          { path: ":currency", element: <SpotWalletDetails /> },
          { path: ":currency/send", element: <SpotWalletSend /> },
          { path: ":currency/deposit", element: <SpotWalletDeposit /> },
          {
            path: ":currency/create-recurring-buys",
            element: <SpotCreateRecurringBuys />,
          },
          { path: ":currency/buy", element: <SpotWalletBuy /> },
          { path: ":currency/sell", element: <SpotWalletSell /> },
          { path: ":currency/swap", element: <SpotWalletSwap /> },
          { path: ":currency/viewaddresses", element: <SpotViewAddress /> },
          {
            path: ":currency/transactions/:id",
            element: <SpotTransactionDetails />,
          },
          {
            path: ":currency/orders/:id",
            element: <LimitOrderDetails />,
          },

          { path: ":currency/add", element: <FiatPaymentOptions /> },
          { path: ":currency/add/connect", element: <Connect /> },
          { path: ":currency/add/voucher", element: <VoucherDeposit /> },
          {
            path: ":currency/add/mobile-money",
            element: <MobileMoneyDeposit />,
          },
          { path: ":currency/withdraw", element: <FiatWithdrawals /> },
          {
            path: ":currency/withdraw/connect",
            element: <ConnectWithdrawal />,
          },
          {
            path: ":currency/withdraw/mobile-money",
            element: <MobileMoneyPayout />,
          },
          // {
          //   path: ":currency/withdraw/connect-express",
          //   element: <ConnectExpress />,
          // },
          // {
          //   path: ":currency/withdraw/connect-prime",
          //   element: <ConnectPrime />,
          // },
          {
            path: ":currency/network/:type",
            element: <SpotSelectMobileNetwork />,
          },
          {
            path: ":currency/vouchers",
            element: <SpotSelectVoucher />,
          },
          {
            path: ":currency/bet",
            element: <SpotSelectBet />,
          },

          {
            path: ":currency/recurring-buys/:id",
            element: <SpotManageRecurringBuys />,
          },

          { path: ":currency/bank-transfer", element: <SpotBankTransfer /> },
          {
            path: ":currency/fiat-deposit-details",
            element: <FiatDepositDetails />,
          },
          {
            path: ":currency/transactions",
            element: <SpotTransactions />,
          },
          {
            path: ":currency/payment-options",
            element: <USDPaymentOptions />,
          },
        ],
      },
      {
        path: "trade",
        element: <Outlet />,
        children: [
          { index: true, element: <Trade /> },
          { path: "transactions", element: <TradeTransactions /> },
          { path: "transactions/:id", element: <TradeTransactionDetails /> },
        ],
      },
      {
        path: "ramp",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <RampTransfer />,
          },
          {
            path: ":id",
            element: <RampTransferDetails />,
          },
        ],
      },
      {
        path: "profile",
        element: (
          <KYBStepNavigationProvider noRedirect>
            <Outlet />
          </KYBStepNavigationProvider>
        ),
        children: [
          { index: true, element: <Profile /> },

          {
            path: "team",
            children: [
              { index: true, element: <Team /> },
              { path: "add", element: <AddTeamMember /> },
              { path: "roles/:role", element: <Roles /> },
            ],
          },

          { path: "about-us", element: <AboutUs /> },

          { path: "documents", element: <VerificationDocuments /> },

          { path: "verification-review", element: <VerificationReview /> },
          {
            path: "verification-submission",
            element: <VerificationSubmission />,
          },

          //
          {
            path: "accounts",
            element: <Outlet />,
            children: [
              { index: true, element: <Accounts /> },
              { path: "personal-details", element: <PersonalDetails /> },
              { path: "business-details", element: <BusinessDetails /> },
              {
                path: "personal-details/change-name",
                element: <ProfileChangeName />,
              },
              { path: "refer-and-earn", element: <ReferAndEarn /> },
              { path: "account-limits", element: <AccountLimits /> },
              { path: "levels", element: <LevelsAndLimits /> },
              { path: "statements", element: <AccountStatements /> },
              { path: "next-of-kin", element: <EditNextOfKin /> },
            ],
          },
          {
            path: "payment-methods",
            element: <Outlet />,
            children: [
              { index: true, element: <PaymentMethods /> },
              { path: "bank-accounts", element: <BankAccounts /> },
              {
                path: "bank-accounts/:id",
                element: <BankAccountDetails />,
              },
              { path: "bank-accounts/add", element: <BankAccountsAdd /> },
              { path: "other-accounts", element: <OtherPaymentAccounts /> },
              { path: "wallet-addresses", element: <WalletAddresses /> },
              {
                path: "other-accounts/add",
                element: <OtherPaymentAccountsAdd />,
              },
            ],
          },
          {
            path: "security",
            element: <Outlet />,
            children: [
              { index: true, element: <Security /> },
              { path: "two-step-auth", element: <ProfileTwoStepAuth /> },
              { path: "reset-password", element: <ResetPassword /> },
              { path: "one-step-withdrawal", element: <OneStepWithdrawal /> },
              {
                path: "manage-onestep-withdrawal",
                element: <ManageOneStepWithdrawal />,
              },
            ],
          },
          {
            path: "preferences",
            element: <Outlet />,
            children: [
              { index: true, element: <Preferences /> },
              { path: "notifications", element: <NotificationSettings /> },
            ],
          },
          {
            path: "developer-tools",
            children: [
              { index: true, element: <DeveloperTools /> },
              {
                path: "api-keys",
                children: [
                  { index: true, element: <ApiKeys /> },
                  { path: ":action", element: <ApiKeysAction /> },
                ],
              },
              {
                path: "webhooks",
                children: [
                  { index: true, element: <Webhooks /> },
                  {
                    path: "create",
                    element: <CreateWebhook />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        element: (
          <SpendContainer>
            <Outlet />
          </SpendContainer>
        ),
        children: [
          {
            path: "/airtime",
            element: <Outlet />,
            children: [
              { index: true, element: <SpendAirtime /> },
              { path: ":network/:countryCode", element: <BuySpendAirtime /> },
              { path: "transactions", element: <SpendTransactions /> },
              { path: "beneficiaries", element: <SpendBeneficiaries /> },
              {
                path: "transactions/:id",
                element: <SpendTransactionDetails />,
              },
            ],
          },
          {
            path: "/data",
            element: <Outlet />,
            children: [
              { index: true, element: <SpendData /> },
              { path: ":network/:countryCode", element: <BuySpendData /> },
              { path: "transactions", element: <SpendTransactions /> },
              { path: "beneficiaries", element: <SpendBeneficiaries /> },
              {
                path: "transactions/:id",
                element: <SpendTransactionDetails />,
              },
            ],
          },
          {
            path: "/sports-bet",
            element: <Outlet />,
            children: [
              { index: true, element: <SpendBet /> },
              {
                path: ":platform/:countryCode",
                element: <CreditSpendBettingAccount />,
              },
              {
                path: "selectbetting/:countryCode",
                element: <SelectBetting />,
              },
              { path: "transactions", element: <SpendTransactions /> },
              {
                path: "transactions/:id",
                element: <SpendTransactionDetails />,
              },
            ],
          },
          {
            path: "/vouchers",
            element: <Outlet />,
            children: [
              { index: true, element: <SpendVouchers /> },
              { path: ":store", element: <SpendVoucherStore /> },
              { path: ":store/purchase", element: <PurchaseSpendVoucher /> },
              {
                path: "selectstores/:countryCode",
                element: <SelectVoucherStores />,
              },
              { path: "transactions", element: <SpendVouchersTransactions /> },
              {
                path: "transactions/:id",
                element: <SpendVoucherTransactionDetails />,
              },

              {
                path: "redemption-history/:id",
                element: <VoucherRedemptionHistory />,
              },
            ],
          },
          {
            path: "/cashback",
            element: <CashBack />,
          },
          {
            path: "/refer-and-earn",
            element: <ReferAndEarn />,
          },
          {
            path: "/referred-earnings",
            element: <ReferredEarnings />,
          },
        ],
      },
      {
        path: "/pay-config",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <BushaPayConfig />,
          },
          {
            path: "payment-currency-order",
            element: <BushaPayConfigCurrencyOrder />,
          },
          {
            path: "set-pin",
            element: <BushaPaySetPin />,
          },
          {
            path: "change-pin",
            element: <BushaPayChangePin />,
          },
          {
            path: "remove-pin",
            element: <BushaPaySecurityAuthentication />,
          },
        ],
      },
      {
        path: "*",
        element: <Header> 404 👀</Header>,
      },
    ],
  },
];

export default routesObject;
