import * as React from "react";
import tw, { styled } from "twin.macro";
import { useState } from "react";
import Paths from "app/utils/paths";
import Card from "app/components/ui/Card";
import Text from "app/styles/Text";
import TextField from "app/components/ui/TextField";

import Header from "app/styles/Header";
import { capitalizeStr } from "app/utils/helpers";
import { ReactComponent as CaretRight } from "app/assets/icons/caret-right.svg";
import LoaderContainer from "app/components/ui/LoaderContainer";

import { ReactComponent as SearchIcon } from "app/assets/icons/search-icon.svg";
import useGetInfinitePaymentAccounts from "app/hooks/useGetInfinitePaymentAccounts";
import { ConnectPaymentMethod } from "app/models/paymentMethod";
import SubHeader from "app/styles/SubHeader";
import WalletAddressModal from "./WalletAddressModal";

export interface WalletAddressProps {
  id: string;
  address: string;
  walletName: string;
  network: string;
  memo?: string;
}

interface Props {
  paymentMethod: ConnectPaymentMethod;
}

export default function ListWalletAddress(props: Props) {
  const { paymentMethod } = props;
  const [query, setQuery] = useState("");

  const [walletAddress, setWalletAddress] =
    React.useState<WalletAddressProps | null>();

  const { isLoading, accounts, error, getPaymentAccounts } =
    useGetInfinitePaymentAccounts({
      paymentMethodIDs: paymentMethod.id,
      category: "address_book",
    });

  const filterWalletAddress =
    accounts &&
    accounts.filter((account) => {
      const walletNameField = account.fields.find(
        (field) => field.key === "wallet_name"
      );
      const addressField = account.fields.find(
        (field) => field.key === "address"
      );
      const networkField = account.fields.find(
        (field) => field.key === "network"
      );

      const walletNameMatch = walletNameField?.value
        .toLowerCase()
        .includes(query.toLowerCase());
      const addressMatch = addressField?.value
        .toLowerCase()
        .includes(query.toLowerCase());
      const networkMatch = networkField?.value
        .toLowerCase()
        .includes(query.toLowerCase());

      return walletNameMatch || addressMatch || networkMatch;
    });

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <LoaderContainer
        loading={isLoading}
        error={!!error}
        errorMessage={error}
        onRetry={getPaymentAccounts}
      >
        {filterWalletAddress && (
          <div>
            <div>
              <Header semiBold tw="text-[28px]">
                Wallet address
              </Header>

              <InputContainer>
                <TextField
                  prefix={<SearchIcon tw="fill-transparent" />}
                  label={""}
                  autoComplete="off"
                  value={query}
                  onChange={handleQueryChange}
                  placeholder="Search wallet addresses, labels, currencies"
                />
              </InputContainer>
            </div>

            {filterWalletAddress.length === 0 && query.length === 0 ? (
              <EmptyState>
                <SubHeader>No saved wallet address</SubHeader>
              </EmptyState>
            ) : filterWalletAddress.length === 0 && query.length > 0 ? (
              <EmptyState>
                <SubHeader>Wallet address not found</SubHeader>
                <Text subdued tw="max-w-[400px] mx-auto">
                  We could not find the wallet address you are searching for.
                  Reset your search filter to retry
                </Text>
              </EmptyState>
            ) : (
              <Card tw="mt-[24px] px-[20px] py-[16px]">
                <div>
                  {filterWalletAddress.map((account) => {
                    const address = account.fields.find(
                      (field) => field.key === "address"
                    )?.value;
                    const network = account.fields.find(
                      (field) => field.key === "network"
                    )?.value;
                    const walletName = account.fields.find(
                      (field) => field.key === "wallet_name"
                    )?.value;

                    const memo = account.fields.find(
                      (field) => field.key === "memo"
                    )?.value;

                    return (
                      <Container
                        key={account.id}
                        onClick={() =>
                          setWalletAddress({
                            id: account.id,
                            walletName: walletName!,
                            network: network!,
                            address: address!,
                            memo: memo,
                          })
                        }
                      >
                        <div className="coin">
                          <div tw="flex-shrink-0">
                            <img
                              src={Paths.coinSVG(network!)}
                              alt={network!}
                              tw=" w-[40px] h-[40px] self-center rounded-full object-contain"
                            />
                          </div>

                          <div>
                            <Text semiBold>
                              {capitalizeStr(walletName!) || network}
                            </Text>
                            <Text tw="break-all" className="coin--name">
                              {address}
                            </Text>
                            {memo && (
                              <Text tw="break-all" className="coin--name">
                                Memo: {memo}
                              </Text>
                            )}
                          </div>
                        </div>

                        <div className="">
                          <CaretRight
                            tw="text-black32 dark:text-white60"
                            width={20}
                          />
                        </div>
                      </Container>
                    );
                  })}
                </div>
              </Card>
            )}
          </div>
        )}
      </LoaderContainer>

      <WalletAddressModal
        isOpen={!!walletAddress}
        onClose={() => setWalletAddress(null)}
        walletAddress={walletAddress}
        getPaymentAccounts={getPaymentAccounts}
      />
    </>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: center;
  padding: 16px 0px;
  cursor: pointer;
  border-bottom: 1px solid rgba(91, 97, 110, 0.2);
  ${tw`hover:bg-[rgba(0, 0, 0, 0.01)] dark:hover:bg-black60`};

  &:last-of-type {
    border-bottom: none;
  }

  .coin {
    display: flex;
    align-items: center;
    column-gap: 10px;

    &--name {
      ${tw` text-black32 dark:text-white32 `};
    }
  }
`;

const InputContainer = styled.div`
  ${tw`mt-[16px]`}

  > svg {
    ${tw`mr-3 mt-1`};
    path {
      ${tw`fill-transparent`};
    }
  }
`;

const EmptyState = styled.div`
  ${tw`flex flex-col h-[200px] w-full justify-center text-center mt-[24px] border border-dashed border-[#E5E5E5] dark:border-white30 rounded-[16px] px-4`}

  > p {
    ${tw`mt-[8px]`}
  }
`;
