import { NavLink, useLocation } from "react-router-dom";
import tw, { styled } from "twin.macro";
import clsx from "clsx";

import appLinks from "app/constants/appLinks";
import { useAppTheme } from "app/providers/AppThemeProvider";
import { getScreenWidth } from "app/utils/helpers";
import { useActiveProfile } from "app/providers/ActiveProfileProvider";
import { useProfile } from "app/providers/ProfileProvider";

export default function DashboardMobilenav() {
  const theme = useAppTheme();
  const activeProfile = useActiveProfile();

  const profile = useProfile();

  const location = useLocation();

  // if (!profile) return null;

  // const links = profile.merchant ? appLinks.concat(merchantLinks) : appLinks;

  const trimLinks = appLinks.filter((r) => {
    if (
      Boolean(
        activeProfile?.profile_type === "business" ||
          profile?.country_code === "KE"
      ) &&
      r.label === "Spend"
    ) {
      return false;
    }

    if (
      Boolean(activeProfile?.profile_type !== "business") &&
      r.label === "Ramp"
    ) {
      return false;
    }

    return true;
  });

  return (
    <Container>
      {trimLinks.map(({ label, icon: Icon, url, children }) => {
        const subNavActive = (children ?? []).some((r) =>
          location.pathname.startsWith(r.url)
        );

        return (
          <Link
            key={label}
            to={url}
            className={clsx({ subNavActive })}
            id={label === "Trade" && getScreenWidth() < 768 ? "step-1" : ""}
          >
            <Icon className={theme} />{" "}
            {activeProfile?.profile_type === "business" &&
              (Boolean(
                label === "My wallets" &&
                  activeProfile.permissions?.[0] !== "**"
              )
                ? "Balances"
                : label)}
            {activeProfile?.profile_type === "personal" && label}
          </Link>
        );
      })}
    </Container>
  );
}

const Container = styled.nav`
  ${tw`w-full h-full flex border-t px-[8px]`};

  ${tw`bg-white `};

  ${tw`dark:bg-black dark:border-t-white12`};
`;

const Link = styled(NavLink)`
  ${tw`w-[60px] h-full flex-shrink-0 flex-grow flex flex-col items-center justify-center text-base text-black32 text-center`};

  @media (max-width: 368px) {
    ${tw`text-[12px]`};
  }

  ${tw`dark:text-white32`};

  svg {
    ${tw`w-[24px] h-[24px] mb-[4px]`};
  }

  &.active,
  &.subNavActive {
    ${tw`text-black`};

    ${tw`dark:text-white`};

    svg {
      path {
        fill: transparent;
        fill-opacity: 1;
      }

      &.dark {
        path {
          fill: transparent;
          fill-opacity: 1;
        }
      }
    }
  }

  &:nth-of-type(2),
  &:nth-of-type(3) {
    &.active,
    &.subnav-active {
      svg {
        path {
          fill: #000;
          fill-opacity: 1;
        }

        &.dark {
          path {
            fill: #fff;
            fill-opacity: 1;
          }
        }
      }
    }
  }
`;
