import * as React from "react";

import { ParsedBook } from "app/models/book";
import { useProfile } from "app/providers/ProfileProvider";

import useGetBooks from "./useGetBooks";
import { OrderSide } from "app/models/order";
import { useActiveProfile } from "app/providers/ActiveProfileProvider";

const useBooks = () => {
  const { isFetching, isLoading, error, getBooks, books } = useGetBooks();
  const profile = useProfile();
  const activeProfile = useActiveProfile();

  //TODO find a way to deep check without JSON
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedBooks = React.useMemo(() => books, [JSON.stringify(books)]);

  const findProduct = React.useCallback(
    (asset1: string, asset2: string) => {
      const book = (memoizedBooks || []).find(
        (b) =>
          (b.base.toUpperCase() === asset1.toUpperCase() &&
            b.counter.toUpperCase() === asset2.toUpperCase()) ||
          (b.counter.toUpperCase() === asset1.toUpperCase() &&
            b.base.toUpperCase() === asset2.toUpperCase())
      );

      return book;
    },
    [memoizedBooks]
  );

  const findProductWithProductName = React.useCallback(
    (productName: string) => {
      const book = (memoizedBooks || []).find((b) => b.product === productName);
      return book;
    },
    [memoizedBooks]
  );

  const getLatestBookData = React.useCallback(
    (code: string) => {
      var userCurrency = profile?.currency_code;
      return findProduct(code, userCurrency as string);
    },
    [findProduct, profile?.currency_code]
  );

  const getLatestBookAgainstDisplayCurrency = React.useCallback(
    (code: string) => {
      let displayCurrency =
        activeProfile?.profile_type === "business"
          ? activeProfile.currency_code
          : profile?.display_currency;
      if (displayCurrency && displayCurrency === "USD") {
        displayCurrency = "USDT";
      }
      return findProduct(code, displayCurrency || "USDT");
    },
    [
      findProduct,
      profile?.display_currency,
      activeProfile?.currency_code,
      activeProfile?.profile_type,
    ]
  );

  const getLatestUnformattedBuyPrice = (book: ParsedBook) => {
    const b = getLatestBookData(book.base);
    if (b) {
      return Number.parseFloat(b.buy_price);
    }
    return 0;
  };

  const getBookPrice = React.useCallback(
    (book: ParsedBook, side: OrderSide, isReverseProduct: boolean) => {
      let _price = 0;

      const updatedBook = findProductWithProductName(book.product);

      if (side === "buy") {
        _price = Number.parseFloat(updatedBook?.buy_price || "0");
        if (isReverseProduct) {
          _price = 1 / Number.parseFloat(updatedBook?.sell_price || "0");
        }
      } else {
        _price = Number.parseFloat(updatedBook?.sell_price || "0");
        if (isReverseProduct) {
          _price = 1 / Number.parseFloat(updatedBook?.buy_price || "0");
        }
      }
      return _price;
    },
    [findProductWithProductName]
  );

  const getCounterDecimal = React.useCallback(
    (book: ParsedBook, isReverseProduct: boolean) => {
      let _counterDecimal = Number.parseInt(book.counter_decimals);

      if (isReverseProduct) {
        _counterDecimal = Number.parseInt(book.base_decimals);
      }
      return _counterDecimal;
    },
    []
  );

  const getBaseDecimal = React.useCallback(
    (book: ParsedBook, isReverseProduct: boolean) => {
      let _baseDecimal = Number(book.base_decimals || "8");
      if (isReverseProduct) {
        _baseDecimal = Number(book.counter_decimals || "8");
      }

      return _baseDecimal;
    },
    []
  );

  return {
    isFetching,
    isLoading,
    error,
    getBooks,
    books,
    getLatestBookAgainstDisplayCurrency,
    getLatestBookData,
    getLatestUnformattedBuyPrice,
    findProduct,
    displayCurrency:
      activeProfile?.profile_type === "business"
        ? activeProfile.currency_code
        : profile?.display_currency,
    findProductWithProductName,
    getCounterDecimal,
    getBaseDecimal,
    getBookPrice,
  };
};

export default useBooks;
