import { useInfiniteQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { getUserPaymentAccounts } from "app/api/payment_accounts";

import useUpdatedEffect from "./useUpdatedEffect";

interface Props {
  paymentMethodIDs?: string | string[];
  currency?: string;
  category?: string;
  network?: string;
}

const useGetInfinitePaymentAccounts = (props: Props = {}) => {
  const { paymentMethodIDs, category, network } = props;

  const {
    isLoading,
    isFetching,
    error,
    refetch,
    status,
    hasNextPage,
    fetchNextPage,
    data,
    dataUpdatedAt,
  } = useInfiniteQuery(
    ["payment-accounts", paymentMethodIDs],
    ({ pageParam = 1 }) =>
      getUserPaymentAccounts({
        page: pageParam,
        payment_method: paymentMethodIDs,
        category: category,
        network: network,
      }),
    {
      getNextPageParam: (lastPage, _) =>
        lastPage.data.pagination.page < lastPage.data.pagination.total_pages
          ? lastPage.data.pagination.page + 1
          : undefined,
    }
  );

  useUpdatedEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, dataUpdatedAt]);

  return {
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<any>) as string) : undefined,
    accounts: data?.pages.map((page) => page.data.data).flat(),
    getPaymentAccounts: refetch,
    status,
  };
};

export default useGetInfinitePaymentAccounts;
