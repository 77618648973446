import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import LoaderContainer from "app/components/ui/LoaderContainer";
import { ACTIVE_PROFILE_STORAGE_KEY, HOME_PATH } from "app/constants/variables";
import { useSetToken } from "app/providers/TokenProvider";
import moengage from "@moengage/web-sdk";
import { getUserDetailsFromToken } from "app/lib/encryption";
import storage from "app/lib/storage";

export default function Auth() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const refresh_token = searchParams.get("refresh_token") ?? undefined;
  const previousPathname = searchParams.get("from");

  const navigate = useNavigate();

  const setAppTokens = useSetToken();

  useEffect(() => {
    const accessToken = params.token;
    let tokens = {
      access_token: accessToken,
      refresh_token,
    };

    setAppTokens(tokens);

    async function initializeUser() {
      const data = await getUserDetailsFromToken(accessToken!);
      // REFRESH ACTIVE_PROFILE_ID (IF ANY) TO AVOID PROFILE CONFLICT
      storage.remove(ACTIVE_PROFILE_STORAGE_KEY);
      await moengage.add_unique_user_id(data?.userId);
      await moengage.track_event("login", {
        user_id: data?.userId,
        login_method: data?.userEmail,
        platform: "web",
      });
    }

    initializeUser();

    navigate(previousPathname ?? HOME_PATH);
  }, [navigate, params.token, refresh_token, setAppTokens, previousPathname]);

  return <LoaderContainer loading screen />;
}
