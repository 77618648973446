import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { COUNTRY_FLAG_BASE_URL } from "app/constants/variables";
import { SupportedCountry } from "app/models/country";

import parseError from "app/lib/parseError";
import { getSupportedCountries } from "app/api/countries";

const useGetSupportedCountries = () => {
  const { isLoading, isFetching, error, refetch, data } = useQuery(
    ["supported-countries"],
    () => getSupportedCountries()
  );

  const formatCountryData = (country: SupportedCountry) => ({
    ...country,
    flag: `${COUNTRY_FLAG_BASE_URL}/${country.id.toLowerCase()}.svg`,
  });

  return {
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<any>) as string) : undefined,
    getCountries: refetch,
    countries: data?.data.map(formatCountryData),
  };
};

export default useGetSupportedCountries;
