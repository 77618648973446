import { NavLink, useLocation } from "react-router-dom";
import tw, { css, styled } from "twin.macro";
import { useAppTheme } from "app/providers/AppThemeProvider";
import appLinks, { profileLink } from "app/constants/appLinks";
import { ReactComponent as LogoutIcon } from "app/assets/icons/logout.svg";
import { gotoSignout } from "app/lib/logout";
import { getScreenWidth } from "app/utils/helpers";
import { useActiveProfile } from "app/providers/ActiveProfileProvider";
import { useProfile } from "app/providers/ProfileProvider";
import { useVerificationStatus } from "app/providers/VerificationStatusProvider";

const profileLinks = [profileLink];
const links = appLinks.concat(profileLinks);

export default function DashboardSideNav() {
  const theme = useAppTheme();
  const profile = useProfile();
  const activeProfile = useActiveProfile();
  const verificationStatus = useVerificationStatus();
  const location = useLocation();
  const logout = () => gotoSignout();

  const trimLinks = links.filter((r) => {
    if (
      Boolean(
        activeProfile?.profile_type === "business" ||
          profile?.country_code === "KE"
      ) &&
      r.label === "Spend"
    ) {
      return false;
    }

    if (
      Boolean(
        activeProfile?.profile_type === "business" &&
          activeProfile.role_name === "Viewer"
      ) &&
      r.label === "Profile"
    ) {
      return false;
    }

    if (
      Boolean(activeProfile?.profile_type !== "business") &&
      r.label === "Ramp"
    ) {
      return false;
    }
    return true;
  });

  // if (!profile) return null;

  // const links = profile.merchant
  //   ? appLinks.concat(merchantLinks, profileLinks)
  //   : appLinks.concat(profileLinks);

  return (
    <Container
      css={
        activeProfile?.profile_type === "business" &&
        verificationStatus?.status !== "verified" &&
        tw`h-[calc(100vh-108px)]`
      }
    >
      <NavigationSection>
        {trimLinks.map(({ children, label, icon: Icon, url, exact }) => {
          const hasSubnav = Boolean(children && children.length);
          const openSubnav = (children ?? []).some((r) =>
            location.pathname.startsWith(r.url)
          );

          return (
            <List key={label}>
              <Link
                to={url}
                end={exact ?? true}
                className={openSubnav ? "subnav-active" : undefined}
                id={label === "Trade" && getScreenWidth() > 768 ? "step-1" : ""}
              >
                <Icon className={theme} />
                {activeProfile?.profile_type === "business" &&
                  (Boolean(
                    label === "My wallets" &&
                      activeProfile.permissions?.[0] !== "**"
                  )
                    ? "Balances"
                    : label)}
                {activeProfile?.profile_type === "personal" && label}
              </Link>

              {hasSubnav && openSubnav && (
                <SubNav className="subnav">
                  {children?.map((subLink) => (
                    <Link key={subLink.label} to={subLink.url}>
                      {subLink.label}
                    </Link>
                  ))}
                </SubNav>
              )}
            </List>
          );
        })}
      </NavigationSection>

      <button css={LinkStyles} onClick={logout}>
        <LogoutIcon className={theme} />
        Log out
      </button>
    </Container>
  );
}

const List = styled.li`
  &:nth-of-type(3) {
    > a {
      &.active,
      &.subnav-active {
        svg {
          path {
            fill: #000;
            fill-opacity: 1;
          }

          &.dark {
            path {
              fill: #fff;
              fill-opacity: 1;
            }
          }
        }
      }
    }
  }
`;

const Container = styled.nav`
  ${tw`w-full h-page py-[24px] px-[32px] flex flex-col border-r`};
  ${tw`bg-white `};
  ${tw`dark:bg-black dark:border-r-white12`};
`;

const NavigationSection = styled.ul`
  ${tw`flex-grow`};

  &,
  li {
    ${tw`w-full`};
  }
`;

const LinkStyles = css`
  ${tw`w-full h-[46px] rounded-[8px] px-[16px] flex items-center text-[1.6rem] text-black32 font-semibold mb-[8px] last-of-type:mb-0`};
  ${tw`dark:text-white32`};

  svg {
    ${tw`w-[20px] h-[20px] mr-[10px]`};
  }
`;

const Link = styled(NavLink)`
  ${LinkStyles};

  &.active,
  &.subnav-active {
    ${tw`bg-greyScale10 text-black`};
    ${tw`dark:bg-greyScale90 dark:text-white95`};
  }

  &.subnav-active {
    ${tw`bg-transparent dark:bg-transparent`};
  }
`;

const SubNav = styled.div`
  ${tw`pl-[17px]`};

  ${Link} {
    ${tw`mb-0 border-l border-l-black12 dark:border-l-white12`};
    border-radius: 0px 8px 8px 0px;

    &.active {
      ${tw`border-l-black95 dark:border-l-white95 dark:bg-greyScale90`};
    }
  }
`;
