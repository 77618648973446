import Button from "app/components/ui/Button";
import Modal from "app/components/ui/Modal";
import Header from "app/styles/Header";
import Text from "app/styles/Text";
import { WalletAddressProps } from "./ListWalletAddress";
import useDeletePaymentAccount from "app/hooks/useDeletePaymentAccount";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  walletAddress?: WalletAddressProps | null;
  getPaymentAccounts: () => void;
}

export default function WalletAddressModal(props: Props) {
  const { isOpen, onClose, walletAddress, getPaymentAccounts } = props;

  const { deleteAccount, isLoading } = useDeletePaymentAccount(
    walletAddress?.id!
  );

  const handleDelete = () => {
    deleteAccount(undefined, {
      onSuccess: () => {
        onClose();
        getPaymentAccounts();
      },
    });
  };

  return (
    <Modal open={isOpen} onClose={onClose} showClose={false} tw="max-w-[480px]">
      <div tw="flex flex-col gap-[24px] px-[24px] pb-[24px] pt-[16px]">
        <Header>Manage wallet address</Header>
        <div tw="flex justify-between">
          <Text subdued medium>
            Wallet name
          </Text>
          <Text medium>{walletAddress?.walletName}</Text>
        </div>

        <div tw="flex justify-between">
          <Text subdued medium tw="flex-shrink-0">
            Wallet address
          </Text>
          <div tw="w-[200px] text-end">
            <Text medium tw="break-all">
              {walletAddress?.address}
            </Text>
          </div>
        </div>
        <div tw="flex justify-between">
          <Text subdued medium>
            Network
          </Text>
          <Text medium>{walletAddress?.network}</Text>
        </div>
        {walletAddress?.memo ? (
          <div tw="flex justify-between">
            <Text subdued medium>
              Memo
            </Text>
            <Text medium>{walletAddress?.memo}</Text>
          </div>
        ) : null}

        <Button loading={isLoading} danger onClick={handleDelete}>
          Delete wallet address
        </Button>
      </div>
    </Modal>
  );
}
