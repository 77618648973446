import Modal from "app/components/ui/Modal";
import SubHeader from "app/styles/SubHeader";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";
import { ReactComponent as PadLockClose } from "app/assets/icons/padlock-close.svg";
import { useNavigate } from "react-router-dom";
import useGetSecurity from "app/hooks/useGetSecurity";
import tw, { styled } from "twin.macro";
import media from "app/styles/media";

export default function EnableTwoStepAuthModal() {
  const navigate = useNavigate();
  const { security } = useGetSecurity();

  return (
    <Modal
      open={security?.two_step === false}
      onClose={() => navigate("/profile/payment-methods/bank-accounts")}
      title="Enable 2FA"
      tw="max-w-[480px]"
    >
      <Container>
        <ContentWrapper>
          <PadLockClose tw="w-[80px] h-[80px]" />

          <TextContent>
            <SubHeader big>Enable 2FA</SubHeader>
            <Text medium subdued>
              Two-factor authentication is needed to add a new payment account.
              Please enable your 2FA to continue
            </Text>
          </TextContent>

          <ButtonGroup>
            <Button
              primary
              fullWidth
              onClick={() => navigate("/profile/security/two-step-auth")}
            >
              Enable 2FA
            </Button>
            <Button outline fullWidth>
              Learn More
            </Button>
          </ButtonGroup>
        </ContentWrapper>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  ${tw`p-[24px]`};
`;

const ContentWrapper = styled.div`
  ${tw`flex flex-col items-center gap-[24px]`};
`;

const TextContent = styled.div`
  ${tw`flex flex-col items-center text-center gap-[8px]`};
`;

const ButtonGroup = styled.div`
  ${tw`flex w-full gap-[8px] w-full`};

  ${media.mobile} {
    ${tw`flex-col`}
  }
`;
