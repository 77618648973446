import * as React from "react";

import Page from "app/components/ui/Page";
import useGoBack from "app/hooks/useGoBack";
import LoaderContainer from "app/components/ui/LoaderContainer";
import useGetPaymentMethods from "app/hooks/useGetPaymentMethods";
import ListWalletAddress from "./components/ListWalletAddress";

export default function WalletAddresses() {
  const goBack = useGoBack();

  const {
    methods: paymentMethod,
    isLoading,
    error,
    refetchPaymentMethods,
  } = useGetPaymentMethods({
    category: "address_book",
  });

  return (
    <Page breadCrumbs={{ label: "Back", onAction: goBack }}>
      <LoaderContainer
        loading={isLoading}
        error={!!error}
        errorMessage={error}
        onRetry={refetchPaymentMethods}
      >
        <ListWalletAddress paymentMethod={paymentMethod[0]} />
      </LoaderContainer>
    </Page>
  );
}
